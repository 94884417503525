<template>
  <v-layout column align-center>
    <v-flex xs12 md8 class="text-center">
      <h2 class="success--text">Merci !</h2>
    </v-flex>
    <v-flex xs12 md8 class="text-center" v-if="!isCancel">
      <p></p>
      <p class="my-5">Nous avons bien reçu votre demande.</p>
      <p>
        Nous reprendrons rapidement contact avec vous pour vous proposer un rendez vous.
      </p>
      <p class="my-5">Dans le cas contraire et cela dans les 3 jours qui suivent votre inscription,<br> d’appeler le <b>04.12.01.15.15</b>.</p>
    </v-flex>
    <v-flex  xs12 md8 class="text-center" v-else>
      <p class="my-5">Nous avons bien reçu votre annulation.</p>
    </v-flex>
    <v-flex xs12 md8 class="text-center mt-5" v-if="isMineur">
      <p>N'oublier pas de vous munir d'une autorisation parental, comme celle ci :</p>
      <iframe :src="`${publicPath}autorisation_parental_covid.pdf`" style="width: 100%;height: 300px; border: none;">
        Oops! an error has occurred.
      </iframe>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      isMineur: this.$router.options.routes[0].mineur,
      publicPath: process.env.BASE_URL,
      isCancel: this.$router.options.routes[0].cancel
    }
  },
};
</script>
