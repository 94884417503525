import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);


export default new Vuetify({
  icons: {
    iconfont: 'md', // default - only for display purposes
    rating:{
      ratingEmpty:'md-vaccines-outlined',
      ratingFull: 'md-vaccines'
    }
  },
  theme: {
    themes: {
      light: {
        primary: colors.cyan.lighten2, // #E53935
        secondary: colors.cyan.lighten5, // #FFCDD2
        accent: colors.teal.base, // #3F51B5
      },
    },
  },
})
