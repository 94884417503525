<template>
  <v-card elevation="0">
    <v-form v-model="valid" ref="form" lazy-validation>
      <h3 class="text-left">Coordonnées :</h3>
      <v-container>
        <v-row>
          <v-col cols="6"
            ><v-text-field
              label="Nom"
              name="nom"
              v-model="content.name"
              :rules="rules.nameRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Prénom"
              name="prenom"
              v-model="content.surname"
              :rules="rules.nameRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-row justify="center">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date"
                    label="Jour du rendez-vous"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                    ref="datePicker"
                    :rules="rules.dateRules"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="menu1 = false"
                  locale="fr"
                  first-day-of-week="1"
                  no-title
                  :min="parseDate()"
                ></v-date-picker>
              </v-menu>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row justify="center">
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="time"
                    label="Heure du rendez-vous"
                    prepend-icon="access_time"
                    readonly
                    v-on="on"
                    :rules="rules.timeRules"
                    :disabled="Timedisabled"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="time"
                  full-width
                  locale="fr"
                  format="24hr"
                  :allowed-minutes="allowedStep"
                  @click:minute="$refs.menu.save(time)"
                  :min="min"
                  :max="max"
                ></v-time-picker>
              </v-menu>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <h3 class="text-left pt-3">Téléphone :</h3>
      <v-container>
        <v-row>
          <v-col cols="6"
            ><v-text-field
              label="Fixe"
              name="phone"
              ref="phone"
              @input="refPortable()"
              v-model="content.phone.fixe"
              :rules="rules.fixePhoneRules"
              :required="content.phone.mobil !== ''"
            ></v-text-field
          ></v-col>
          <v-col cols="6"
            ><v-text-field
              label="Mobile"
              name="portable"
              ref="portable"
              @input="refPhone()"
              v-model="content.phone.mobile"
              :rules="rules.mobilePhoneRules"
              :required="content.phone.fixe !== ''"
            ></v-text-field
          ></v-col>
        </v-row>
      </v-container>
      <h3 class="text-left pt-3">Email :</h3>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="E-mail"
              name="email"
              v-model="content.email"
              :rules="rules.emailRules"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
        <v-row> </v-row>
      
      <h3 class="text-left pt-3">Raison</h3>
      <v-container>
        <v-radio-group v-model="content.raison" class="mx-auto" :rules="rules.notEmpty">
          <v-radio
            :label="reason.label"
            :value="reason.value"
            v-for="reason in reasons"
            :key="reason.id"
          ></v-radio>
        </v-radio-group>
      </v-container>
      <v-card-actions>
        <v-btn text color="primary" value="1" @click.prevent="retour">
          <v-icon left>arrow_back_ios</v-icon>
          Retour
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click.prevent="continuer" :disabled="!valid">
          Continue
          <v-icon right>arrow_forward_ios</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
  
<script>
export default {
  data() {
    return {
      menu1: false,
      menu2: false,
      valid: true,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      time: "",
      h24: (60*60*24*1000),
      
      min: "06:00",
      max: "18:35",
      content: {
        name: "",
        surname: "",
        raison: "",
        date: "",
        time: "",
        phone: {
          fixe: "",
          mobile: "",
        },
      },
      reasons: [
        {
          label: "Prise de rendez-vous ailleurs",
          value: "ailleurs",
        },
        {
          label: "Je suis malade",
          value: "malade",
        },
        {
          label: "J'ai le COVID",
          value: "covidé",
        },
        {
          label: "Autres",
          value: "autres",
        },
      ],
      rules: {
        notEmpty: [(v) => !!v || "Champ requis"],
        nameRules: [
          (v) => !!v || "Champs requis",
          (v) =>
            (v && v.length >= 3) ||
            "Votre nom doit contenir au moins 3 caractères",
        ],
        emailRules: [
          (v) => !!v || "E-mail est requis",
          (v) =>
            /^\w+([.-]?\w+)*@\w+([.-]?w+)*(.\w{2,3})+$/.test(v) ||
            "E-mail incorrect",
        ],
        fixePhoneRules: [
          (value) =>
            !!value ||
            this.checkPhoneValidation ||
            "au moins 1 téléphone doit être complété",
          (value) =>
            /(^$|^\d{10}$)/.test(value) || "Ce téléphone est incorrect",
        ],
        mobilePhoneRules: [
          (value) =>
            !!value ||
            this.checkPhoneValidation ||
            "au moins 1 téléphone doit être complété",
          (value) =>
            /(^$|^(06|07)[0-9]{8}$)/.test(value) ||
            "Ce téléphone est incorrect",
        ],
        dateRules: [
          (v) => !!v || "La date du rendez-vous est requise",
          // () => this.validateDateTime() || "Le rendez-vous doit être futur"
        ],
        timeRules: [
          (v) => !!v || "L'heure du rendez-vous est requise",
          // () => (new Date(this.date)).getTime() > Date.now() || "Le rendez-vous doit être futur"
        ],
      },
    };
  },
  computed: {
    Timedisabled() {
      const d = new Date(this.date);
      return ((d.getHours() >= 18 && d.getMinutes() > 35)
          || (d.getHours <= 6 && d.getMinutes == 0) )
    },
    checkPhoneValidation() {
      return this.content.phone.fixe != "" || this.content.phone.mobile != "";
    },
    submitableDateTime() {
        const date = new Date(this.date);
        if (typeof this.time === "string") {
          const hours = this.time.match(/^(\d+)/)[1];
          const minutes = this.time.match(/:(\d+)/)[1];
          date.setHours(hours);
          date.setMinutes(minutes);
        } else if(this.time !== null){
          date.setHours(this.time.getHours());
          date.setMinutes(this.time.getMinutes());
        }
        return date;
    },
  },

  methods: {
    allowedStep(m){
      return m % 5 === 0
    },
    parseDate(date) {
      const d = ( new Date(date) ).getTime() || ( new Date() ).getTime() + this.h24;
      if ((new Date(d)).getHours() > 18 &&  (new Date(d)).getMinutes() > 35) d + this.h24
      const [day, month, year] = (new Date(d)).toLocaleDateString('fr').split('/');
      return `${year}-${month}-${day}`
    },
    parseTime(date) {
      // const d = date || (new Date()).getTime() + this.h24;
      console.log(date)
      const d = new Date(date) || new Date();
      console.log(d);
      if ((d.getHours() >= 18 && d.getMinutes() > 35)
          || (d.getHours <= 6 && d.getMinutes == 0) ){
            return '06:00'
      }
      return (new Date(d)).toLocaleTimeString('fr');
    },
    refPhone() {
      this.$refs.phone.resetValidation();
      this.$refs.phone.validate();
    },
    refPortable() {
      this.$refs.portable.resetValidation();
      this.$refs.portable.validate();
    },
    continuer() {
      if (this.$refs.form.validate()) {
        this.content.date = this.submitableDateTime.toLocaleDateString();
        this.content.time = this.submitableDateTime.toLocaleTimeString();

        this.$emit("submit", {
          form: this.content,
          stepForm: 2,
        });
      }
    },
    retour() {
      this.$emit("retour", 1);
    },
  }
};
</script>