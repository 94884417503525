<template>
<v-container>
    <v-divider class="mb-5"></v-divider>
    <v-row>
        <v-col><h2 class="font-weight-black text-center mt-2 mb-5 pb-5">Liens Utiles</h2></v-col>
    </v-row>
    
  <v-row class="mx-auto align-baseline">
      <v-col cols=4 class="mb-4 justify-content-center" v-for="link in links" :key="link.id">
        <v-card class="pa-2 mx-auto">
          <a :href="link.url" target="_blank" class="text-decoration-none">
            <img class="mt-sm-3" :src="link.image" max-height="143px" width="100%" /> 
            <v-card-actions class="d-none d-sm-flex">
                <v-spacer></v-spacer>
                <v-btn text class="caption blue--text">{{link.text}} <v-icon right x-small>launch</v-icon></v-btn>
            </v-card-actions>
          </a>
        </v-card>
      </v-col>
    </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            links: [
                {
                    text: 'Gouvernement',
                    url: 'https://www.gouvernement.fr/info-coronavirus',
                    image: require('@/assets/gouvernement.svg')
                },
                {
                    text: 'Santé Publique France',
                    url: 'https://www.santepubliquefrance.fr/l-info-accessible-a-tous/coronavirus',
                    image: require('@/assets/sante-publique.svg')
                },
                {
                    text: 'Centre hospitalier Alpes Léman',
                    url: 'http://www.ch-alpes-leman.fr/',
                    image: require('@/assets/ch-alpes-leman.jpg')
                }
            ]
        }
    },

}
</script>

<style>

</style>