import Vue from 'vue'
import VueRouter from 'vue-router'

import ViewHome from '../view/Home'
import ViewCondition from '../view/Condition'
import ViewInscription from '../view/Inscription'
import ViewAnnulation from '../view/Annulation'
import ViewFinnish from '../view/Finnish'
import ViewErreur from '../view/Erreur'

Vue.use(VueRouter)

const router = new VueRouter({
    routes: [
        {
            path: '*',
            components: {
                home: ViewHome,
                condition: ViewCondition,
                inscription: ViewInscription,
                annulation: ViewAnnulation,
                finnish: ViewFinnish,
                erreur: ViewErreur,
            },
            mineur: false,
            vaccins: 3,
            cancel: false
        }
    ],
    mode: 'history'
})

export default router