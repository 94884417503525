<template>
  <v-bottom-sheet
      v-if="keyPath == 2"
      dark
      value="rgpd"
      v-model="rgpd"
    >
      <v-container>
        <v-row align="center">
          <v-col offset-md="1" cols="9">
            <p class="caption text-center">
              Les données que vous nous transmettez sont utilisées dans le but
              de vérifier votre demande faites et de vous re-contacter. Ces
              données sont utilisées par UML74.fr, responsable du traitement des
              données, et ne seront jamais cédées à un tiers ni utilisées à
              d’autres fins que celles détaillées précédemment.
            </p>
          </v-col>
          <v-col cols="1">
            <v-btn color="dark" dark @click="rgpd = false"
              ><v-icon>done_outline</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-bottom-sheet>
</template>

<script>
export default {
    props: ['keyPath'],
    data() {
        return {
            rgpd: true
        }
    },

}
</script>

<style>

</style>