<template>
  <v-card-text>
    <p>
      Comme vous le savez, nous faisons actuellement face à la terrible pandémie
      Covid-19 et, en plus des gestes barrière, la vaccination semble être
      aujourd’hui la seule façon de nous permettre d’entrevoir le bout de ce
      tunnel qui dure depuis plus d’une année.
    </p>
    <h4>Face à cette situation</h4>
    <p>
      Face à cette situation, des centres de vaccination massive avec les vaccin
      PFIZER ont été mis en place dans toute la France depuis le mois de janvier
      2021 avec des résultats très encourageants mais, vaccin limité pour
      l’instant aux personnes les plus fragiles avec comorbidités. Ensuite, avec
      l’arrivée du vaccin MODERNA, cette vaccination a pu s’intensifier mais
      toujours et uniquement dans les centres de vaccination de masse pour des
      raisons de conservation de froid drastiques.
    </p>
    <h4>Depuis mi-février 2021</h4>
    <p>
      Depuis mi-février, avec l’arrivée du vaccin AstraZeneca, cette possibilité
      de vaccination a pu être ouverte aussi aux médecins généralistes qui
      souhaitent vacciner dans leur cabinet leurs patients éligibles. Que les
      patients puissent se faire vacciner en toute confiance par leur médecin
      traitant qui les connaît si bien est une excellente chose qui va permettre
      enfin de booster cette vaccination et nous rapprocher de la fin de cette
      sinistre période à laquelle nous sommes confrontés. Ce vaccin, malgré de
      rares effet secondaires, reste très efficace pour vous protéger contre le
      covid-19.
    </p>
    <h4>À partir du lundi 12 avril 2021 :</h4>
    <p>
      À partir du lundi 12 avril 2021,
      <span
        class="text-decoration-underline font-weight-bold pink--text text--accent-4"
        >toute personne de plus de 55 ans pourra être vaccinée contre le
        covid-19</span
      >, et cela sans aucune autre condition qu’elle soit porteuse d’une maladie
      à risque ou pas. Il suffira donc juste d’avoir au moins 55 ans !
    </p>
    <h4>Et à partir de la semaine du lundi 19 Avril 2021 :</h4>
    <p>
      À partir de la semaine du lundi 19 Avril 2021 arrivera aussi chez les
      médecins généralistes de ville qui le souhaitent le tout
      <span
        class="text-decoration-underline font-weight-bold pink--text text--accent-4"
        >nouveau vaccin de Janssen groupe américain Johnson & Johnson</span
      >
    </p>
    <h4>Dans notre secteur géographique</h4>
    <p>
      Dans notre secteur géographique Annemassien, comme dans beaucoup d’autres
      d’ailleurs, se pose bien sûr le problème de la pénurie médicale et des
      personnes qui n’ont pas, ou n’ont plus, de médecin traitant.
    </p>
    <p>
      C’est dans ce contexte-là et pour leur venir en aide, que certains
      médecins de la structure UML se proposent, de venir quelques matinées sur
      leur jour de repos pour les vacciner avec les vaccins disponibles
    </p>
    <p>
      En effet, depuis plus de 21 ans, la structure UML a pour philosophie
      d’assurer exclusivement la prise en charge ponctuelle de patients en
      l’absence de leur médecin traitant ou d’urgences demandées par le centre
      15, mais n’a jamais eu vocation à être le médecin traitant de quiconque,
      et encore moins a faire du suivi médical, du renouvellement d’ordonnances
      ou des vaccinations.
    </p>
    <p>
      Mais face à la situation de crise exceptionnelle, certains médecins de la
      structure se sont donc portés volontaires sur leur temps libre pour venir
      participer à cette campagne de vaccination Covid-19 au cabinet médical des
      UML.
    </p>
    <h4 class="onward">Ainsi, a été mise en place depuis le 23 mars 2021</h4>
    <p class="onward">
      Ainsi, a été mise en place depuis le 23 mars 2021, pour les personnes du
      secteur sans médecin traitant la possibilité d’avoir dans notre cabinet
      médical des UML cette vaccination sur rendez-vous, et cela selon arrivée
      de doses de vaccin.
    </p>
    <h4 class="onward">À compter du Mardi 1<sup>er</sup> juin 2021 :</h4>
    <p class="onward">
        Toute personne à partir de 18 ans pourra etre vaccinée sans autres
        conditions.
    </p>
    <h4 class="onward">Et depuis le lundi 15 juin 2021 :</h4>
    <p class="onward">
      <span class="font-weight-bold pink--text text--accent-4"
        >Toute personne à partir de 12 ans pourra être vaccinée avec présentation de l’accord parental écrit pour les moins de 16 ans.</span
      >
    </p>
    <br>
    <p class="onward">
        Actuellement  le seul vaccin utilisé par les médecins umlvax est le <b><u>Pfizer</u></b> pour toutes les injections de la 1<sup>ère</sup> à la dernière.
    </p>
  </v-card-text>
</template>

<script>
export default {
  methods: {},
};
</script>

<style lang="css" scoped>
p,
span.indent {
  text-indent: 1.5em;
}
p + p,
p ~ p {
  margin-bottom: 0;
}
h4 {
  margin-top: 16px;
  margin-bottom: 8px;
}
.onward {
  font-size: 1rem;
  color: black;
}
</style>