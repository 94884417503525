<template>
  <v-app-bar dark app color="primary" height="74px">
    <div class="d-flex align-center">
      <img
        src="@/assets/logo_umlvax_2.svg"
        height="90%"
        class="mr-3"
        @click.prevent="accueil()"
      />
      <h1 class="hidden-xs-only">UMLVAX ANNEMASSE</h1>
    </div>
    <!-- <v-spacer></v-spacer>
    <v-btn to="/" text>
        <v-icon>home</v-icon>
    </v-btn>
    <v-btn to="/equipe" text>
        <v-icon left>groups</v-icon>
        Equipe Médicale</v-btn> -->
  </v-app-bar>
</template>

<script>
export default {
  methods: {
    accueil() {
      this.$emit("accueil");
    },
  },
};
</script>

<style lang="css" scoped>
img:hover {
  cursor: pointer;
}
</style>