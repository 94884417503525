<template>
  
    <v-layout row wrap>
      <v-flex xs12 lg11 xl8 mx-auto>
      <h2 class="pink--text text--accent-4 text-center"><p><b>A partir de 12 ans avec accord parental ecrit pour les moins de 16 ans</b></p></h2>
      </v-flex>
      <v-flex xs12 lg112 xl10 mx-auto elevation-2>
        
          <v-row>
            <v-col style="overflow: hidden" class="d-none d-md-flex">
              <img src="@/assets/vaxin_large.jpg" height="100%"/></v-col>
            <v-col>
              <v-card class="elevation-0">
              <img src="@/assets/vaxin_large.jpg" width="100%" class="d-sm-flex d-md-none"/>
              <description />
              <v-card-actions>
            <v-spacer></v-spacer>
           <btn-next text="continuer" value="1" @changeKey="next"/>
          </v-card-actions>
        </v-card>
            </v-col>
          </v-row>
      </v-flex>
      <v-flex  xs12 lg11 xl8 class="mx-auto mt-5 mb-3">
         <lien-util />
      </v-flex>
    </v-layout>
</template>

<script>
import BtnNext from '../components/BtnNext.vue';
import Description from "../components/Description";
import LienUtil from '../components/LienUtil';
export default {
  components: { Description, BtnNext, LienUtil },
  data () 
    {
    return {
      continu: false,
    };
  },
  methods: {
    next(arg) {
      this.$emit('changeKey', arg)
    },
  },
  mounted(){
    this.$router.options.routes[0].cancel = false;
  }
};
</script>

<style>
h3 {
  text-align: center;
}
</style>