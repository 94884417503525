<template>
  <v-btn class="accent" large @click.prevent="changeKey()">
    {{ text }}
    <v-icon right medium class="seringue">colorize</v-icon>
    <!-- <v-icon right>navigate_next</v-icon> -->
  </v-btn>
</template>

<script>
export default {
  props: ["text", "value"],
  methods: {
    changeKey() {
      this.$emit("changeKey", this.value);
    },
  },
};
</script>
<style lang="css" scoped>
.seringue {
  transform-origin: center center;
  transform: rotateZ(-135deg);
}
</style>