<template>
  <v-layout row wrap>
    <v-flex xs12 md10 lg6 mx-auto>
      <v-stepper v-model="stepForm">
        <v-stepper-content step="1">
          <form-annulation @submit="continu" @retour="retour"></form-annulation>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-btn text color="primary" @click.prevent="stepForm = 1">
            <v-icon left>arrow_back_ios</v-icon>
            Retour
          </v-btn>
          <form-files @submit="submit" :ActivateCIdentity="true" :ActivateCVital="false"></form-files>
        </v-stepper-content>
      </v-stepper>
    </v-flex>
  </v-layout>
</template>
  
<script>
import FormAnnulation from "../components/Form/FormAnnulation";
import FormFiles from "../components/Form/FormFiles";
import axios from "axios";

export default {
  components: { FormAnnulation, FormFiles },
  data() {
    return {
      stepForm: 1,
      form_data: new FormData(),
    };
  },
  methods: {
    continu({ form, stepForm }) {
      this.stepForm = stepForm;
      this.form_data.append('user', `${form.name} ${form.surname}`)
      
      this.form_data.append('email', form.email)
      this.form_data.append('date', form.date)
      this.form_data.append('time', form.time)
      this.form_data.append('raison',form.raison);
      this.form_data.append('fixe', form.phone.fixe);
      this.form_data.append('mobile', form.phone.mobile);
    },
    submit(form) {
      this.form_data.append("identity", form.identity);
      
      axios
        .post("api/cancel.php", this.form_data)
        .then((res) => {
          if(res.data.status){
            this.$router.options.routes[0].cancel = true;
            this.$emit('changeKey', 4);
          }else{
            this.$emit('changeKey', 5);
          }
        })
        .catch((error) => {console.error(error) ; this.$emit('changeKey', 5)});
    },
    retour(args){
      this.$emit('changeKey',args)
    }
  },
};
</script>