<template>
  <v-app app>
    <vax-header @accueil="keyPath = 0"></vax-header>
    <v-main>
      <v-container>
        <h2 class="text-center mt-2 mb-1 pb-5">
          - Centre médical UML -
          <br />
          Inscription à la Vaccination covid-19
        </h2>
        <router-view
          :name="paths[keyPath]"
          @changeKey="changeKey"
          class="mb-5"
        ></router-view>
      </v-container>
    </v-main>
    <vax-bottom-sheet :keyPath="keyPath"></vax-bottom-sheet>
    <vax-footer />
    
  </v-app>
</template>

<script>
import VaxHeader from "./components/theme/VaxHeader";
import VaxBottomSheet from "./components/theme/VaxBottomSheet";
import VaxFooter from "./components/theme/VaxFooter";

export default {
  components: { VaxHeader, VaxFooter, VaxBottomSheet },
  name: "App",
  data() {
    return {
      keyPath: 0,
      paths: [],
    };
  },
  methods: {
    changeKey(value) {
      this.keyPath = value;
    },
    pathSetting() {
      this.paths = Object.keys(this.$router.options.routes[0].components);
    },
  },
  created() {
    this.pathSetting();
  },
};
</script>
<style lang="css" >
* {
  font-family: "Open Sans", sans-serif;
}
body {
  background: #fafafe;
}
h1 {
  font-family: "Jura", sans-serif;
}
small {
  color: grey;
}
.v-bottom-sheet {
  background: #bbdefb;
}
</style>
