<template>
  <v-footer height="auto" class="grey darken-3 pt-5" app absolute dark style="bottom: 0px">
      <v-layout row wrap justify-center>
         <v-flex xs12 py-3 text-center >
          &copy;2021 Copyright : UMLVAX
        <br>
            <v-btn dark text tag="a" href="mailto:umlvax@gmail.com" class="text-sm-body-2">Contacter UMLVAX</v-btn>
        </v-flex>
      </v-layout>
    </v-footer>
</template>

<script>
export default {

}
</script>

<style>

</style>