  <template>
  <v-flex xs12 md8 lg5 mx-auto>
    <v-card class="pa-2" v-if="!isRdv && !isCancel">
      
      <v-card-text class="text-left">
        <v-row wrap>
          <v-col cols="12" md="8" lg="8">
            <p>Vous souhaitez :</p>
            <v-form class="mx-auto">
              <v-radio-group v-model="mode" class="mx-auto" >
                <v-radio label="Prendre un rendez-vous" value="rdv"></v-radio>
                <v-radio label="Annuler un rendez-vous" value="annulation"></v-radio>
              </v-radio-group>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <div v-if="mode !== ''">
        <v-card-action class="text-right">
            <span>cliquez sur le bouton pour poursuivre</span>
            <v-spacer />
            <btn-next text="Continuer" value="3"  @changeKey="next"  v-if="mode === 'annulation'" />
            <v-btn class="accent" large @click.prevent="chooseMode()" v-if="mode === 'rdv'">
              Continuer
              <v-icon right>navigate_next</v-icon>
            </v-btn>
          </v-card-action>
          </div>
        
    </v-card>

    <v-card class="pa-2" v-if="isCancel && ! isRdv">
      <v-card-text class="text-center">
        <v-btn text color="info" @click.prevent="retourMode()">- Retour -</v-btn>
      </v-card-text>
    </v-card>
    <v-card class="pa-2" v-if="isRdv && !isCancel">
      <v-card-text v-if="conditionsValue" class="text-center"><v-btn text color="info" @click.prevent="retour()">- Retour -</v-btn></v-card-text>
      <v-card-text v-else>
      <p class="text-center">
        Merci de valider les conditions indispensables pour vous inscrire
      </p>
      <v-row wrap>
        <v-col cols="12" md="8" lg="8" class="mx-auto">
          <v-form class="mx-auto">
            <v-checkbox label="Je n'ai pas de médecin traitant dans le secteur / mon médecin ne vaccine pas encore." v-model="medecin"></v-checkbox>
            <v-checkbox label="J'ai 18 ans ou plus." v-model="majeur" :disabled="mineur"></v-checkbox>
            <v-checkbox label="Je suis mineur mais j'ai l'accord parental ecrit et signé par au moins un de mes parents." v-model="mineur" :disabled="majeur" ></v-checkbox>
          </v-form>
        </v-col>
      </v-row>
      <v-row wrap>
        <v-col cols=1>
              <v-icon color="primary">help_outline</v-icon>
            </v-col>
            <v-col>
              <p class="caption text-justify">
                Pour des raisons logistiques et de sécurité, <b>les personnes de
                plus de 80 ans</b> ne pourront pas, pour le moment, bénéficier de
                cette vaccination dans notre structure.
              </p>
            </v-col>
      </v-row>
      </v-card-text>
      <v-card-text v-if="conditionsValue">
      <v-row wrap>
        <v-col>
          <p class="text-center mt-3">
            Vous avez validé les conditions indispensables pour vous
        inscrire.
          </p>
          <p v-if="mineur">Veuillez compléter ce <a :href="`${publicPath}autorisation_parental_covid.pdf`" target="_blank">formulaire</a></p>
        </v-col>
      </v-row>
      </v-card-text>
      <v-card-actions v-if="conditionsValue" class="align-center">
        <span>cliquez sur le bouton pour poursuivre</span>
        <v-spacer></v-spacer>
        <btn-next text="s'inscrire" value="2" @changeKey="next" />
      </v-card-actions>
    </v-card>
  </v-flex>
</template>
  
  <script>
import BtnNext from "@/components/BtnNext";
export default {
  components: {
    BtnNext
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      mineur: false,
      majeur:false,
      medecin: false,
      mode:"",
      isCancel:false,
      isRdv: false
    };
  },
  computed: {
    conditionsValue: function () {
      var c = false
      if((this.majeur || this.mineur) && this.medecin)
      { c = this.itsok() }
      return c
    },
  },
  methods: {
    chooseMode(){

      if(this.mode === 'rdv'){
        this.isRdv = true
        this.isCancel = !this.isRdv
      }else if (this.mode === 'annulation') {
        this.isCancel = true
        this.isRdv = !this.isCancel
      }
    },
    next(args) {
      this.$router.options.routes[0].mineur = this.mineur;
      this.$emit("changeKey", args);
    },
    retourMode(){
      this.mode = null
      this.isRdv = false
      this.isCancel = false
    },
    retour() {
      this.mineur = false
      this.majeur = false
      this.medecin = false
      this.retourMode()
    },
    itsok(){
      return setTimeout(()=> {return true} , 750);
    }
  },
};
</script>
