<template>
  <v-card elevation="0">
    <v-form v-model="valid" ref="form" lazy-validation>
      <h3>Coordonnées :</h3>
      <v-container>
        <v-row>
          <v-col cols="6"
            ><v-text-field
              label="Nom"
              name="nom"
              v-model="content.name"
              :rules="rules.nameRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Prénom"
              name="prenom"
              v-model="content.surname"
              :rules="rules.nameRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-container>
              <v-row align="center">
                <v-col cols="12" sm="4">
                  <label>Date de naissance</label>
                </v-col>
                <v-col sm="2">
                  <v-autocomplete
                    label="jour"
                    name="day"
                    v-model="content.birthday.day"
                    :items="range(1, 31)"
                    :rules="rules.notEmpty"
                    auto-select-first
                    required
                  ></v-autocomplete>
                </v-col>
                /
                <v-col sm="2">
                  <v-autocomplete
                    label="mois"
                    name="month"
                    v-model="content.birthday.month"
                    :items="range(1, 12)"
                    :rules="rules.notEmpty"
                    auto-select-first
                    required
                  ></v-autocomplete>
                </v-col>
                /
                <v-col sm="3">
                  <v-autocomplete
                    label="année"
                    name="year"
                    v-model="content.birthday.year"
                    :items="range(2021 - ageMax, ageMax - ageMin)"
                    :rules="rules.notEmpty"
                    auto-select-first
                    no-data-text="année non trouvée"
                    required
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col offset-sm-cols="12">
            <v-text-field
              label="Numéro carte vitale"
              name="cartesecu"
              v-model="content.carteVital"
              :counter="15"
              :rules="rules.secuRules"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <h3>Adresse :</h3>
      <v-container>
        <v-row>
          <v-col cols="2"
            ><v-text-field
              label="N°"
              name="adresse-numero"
              v-model="content.adresse.numero"
              :rules="rules.adresse.NumRules"
              required
            ></v-text-field
          ></v-col>
          <v-col
            ><v-text-field
              label="Nom de la voie"
              placeholder="avenue de Verdun"
              name="adresse-name-way"
              v-model="content.adresse.voie"
              :rules="rules.adresse.VoieRules"
              required
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-text-field
              label="Code postal"
              name="code-postal"
              v-model="content.adresse.cp"
              :rules="rules.adresse.CodePostalRules"
              required
            ></v-text-field>
          </v-col>
          <v-col
            ><v-text-field
              label="Ville"
              name="ville"
              v-model="content.adresse.ville"
              :rules="rules.adresse.VilleRules"
              required
            ></v-text-field
          ></v-col>
        </v-row>
      </v-container>
      <h3>Téléphone :</h3>
      <v-container>
        <v-row>
          <v-col cols="6"
            ><v-text-field
              label="Fixe"
              name="phone"
              ref="phone"
              @input="refPortable()"
              v-model="content.phone.fixe"
              :rules="rules.fixePhoneRules"
              :required="content.phone.mobil !== ''"
            ></v-text-field
          ></v-col>
          <v-col cols="6"
            ><v-text-field
              label="Mobile"
              name="portable"
              ref="portable"
              @input="refPhone()"
              v-model="content.phone.mobile"
              :rules="rules.mobilePhoneRules"
              :required="content.phone.fixe !== ''"
            ></v-text-field
          ></v-col>
        </v-row>
      </v-container>
      <h3>Email :</h3>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="E-mail"
              name="email"
              v-model="content.email"
              :rules="rules.emailRules"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <h3 class="py-3">Pour quelle dose ?</h3>
      <div class="text-center">
        <span class="red--text darken-1" v-if="ruleDose.status">{{
          ruleDose.text
        }}</span>
        <v-rating
          :class="['rounded', (ruleDose.status ? 'border': '')]"
          size="97"
          :length="doses"
          v-model="content.dose"
          required
        >
          <template v-slot:item="props">
            <v-icon
              :color="props.isFilled ? 'warning' : 'grey lighten-1'"
              size="100"
              @click="props.click"
            >
              mdi-needle
            </v-icon>
          </template>
        </v-rating>
      </div>
      <v-card-actions>
        <v-btn text color="primary" value="1" @click.prevent="retour">
          <v-icon left>arrow_back_ios</v-icon>
          Retour
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click.prevent="continuer" :disabled="!valid">
          Continue
          <v-icon right>arrow_forward_ios</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
  
<script>
export default {
  data() {
    return {
      ruleDose: {
        status: false,
        text: "Veuillez indiquer à quelle dose devez-vous être traiter.",
      },
      doses: this.$router.options.routes[0].vaccins,
      valid: true,
      content: {
        name: "",
        surname: "",
        carteVital: "",
        email: "",
        adresse: {
          numero: "",
          voie: "",
          cp: "",
          ville: "",
        },
        phone: {
          fixe: "",
          mobile: "",
        },
        birthday: { day: undefined, month: undefined, year: undefined },
        dose: 0,
      },

      rules: {
        notEmpty: [(v) => !!v || "Champ requis"],
        nameRules: [
          (v) => !!v || "Champs requis",
          (v) =>
            (v && v.length >= 3) ||
            "Votre nom doit contenir au moins 3 caractères",
        ],
        emailRules: [
          (v) => !!v || "E-mail est requis",
          (v) =>
            /^\w+([.-]?\w+)*@\w+([.-]?w+)*(.\w{2,3})+$/.test(v) ||
            "E-mail incorrect",
        ],
        adresse: {
          NumRules: [
            (value) => !!value || "le N° est requis",
            // (value) => /^\d|\dter|\dbis/.test(value) || "le numéro est incorrect",
          ],
          VoieRules: [
            (value) => !!value || "le nom de la voie est requis",
            (value) => / {1,}/.test(value) || "le nom de la voie est incorrect",
          ],
          CodePostalRules: [
            (value) => !!value || "le code postal est requis",
            (value) => /^\d{5}$/.test(value) || "Code postal est incorrect",
          ],
          VilleRules: [
            (value) => !!value || "la ville est requis",
            // (value) => /^\d|\dter|\dbis/.test(value) || "le numéro est incorrect",
          ],
        },
        fixePhoneRules: [
          (value) =>
            !!value ||
            this.checkPhoneValidation ||
            "un téléphone doit être complété",
          (value) =>
            /(^$|^\d{10}$)/.test(value) || "Ce téléphone est incorrect",
        ],
        mobilePhoneRules: [
          (value) =>
            !!value ||
            this.checkPhoneValidation ||
            "un téléphone doit être complété",
          (value) =>
            /(^$|^(06|07)[0-9]{8}$)/.test(value) ||
            "Ce téléphone est incorrect",
        ],

        secuRules: [
          (value) => !!value || "le numéro de carte est requis.",
          (value) => /^\d{15}$/.test(value) || "le numéro de carte incorrect",
        ],
      },
    };
  },
  computed: {
    checkPhoneValidation() {
      return this.content.phone.fixe != "" || this.content.phone.mobile != "";
    },
    ageMax() {
      return this.$router.options.routes[0].mineur ? 16 : 80;
    },
    ageMin() {
      return this.$router.options.routes[0].mineur ? 12 : 16;
    },
  },
  methods: {
    continuer() {
      if (this.content.dose == 0) {
        this.ruleDose.status = true;
      } else if (this.$refs.form.validate()) {
        this.$emit("submit", {
          form: this.content,
          stepForm: 2,
        });
      }
    },
    retour() {
      this.$emit("retour", 1);
    },
    range(startAt = 0, size) {
      return [...Array(size).keys()].map((i) => i + startAt);
    },
    refPhone() {
      this.$refs.phone.resetValidation();
      this.$refs.phone.validate();
    },
    refPortable() {
      this.$refs.portable.resetValidation();
      this.$refs.portable.validate();
    },
  },
};
</script>
<style scoped>
.border {
  border: 1px solid #e53935;
}
</style>