<template>
  <v-layout column align-center>
    <v-flex xs12 md8 class="text-center">
      <h2 class="warning--text">OUPS !</h2>
      <p>Une erreur est survenue pendant l'envoie de votre inscription, veuillez nous en excusez.</p>
      <p>
        Veuillez ré-essayer ultérieurement
      </p>
    </v-flex>
  </v-layout>
</template>

<script>
export default {};
</script>
