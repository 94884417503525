<template>
  <v-layout row wrap>
    <v-flex xs12 md10 lg6 mx-auto>
      <v-stepper v-model="stepForm">
        <v-stepper-content step="1">
          <form-inscription @submit="continu" @retour="retour"></form-inscription>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-btn text color="primary" @click.prevent="stepForm = 1">
            <v-icon left>arrow_back_ios</v-icon>
            Retour
          </v-btn>
          <form-files @submit="submit" :ActivateCIdentity="true" :ActivateCVital="true"></form-files>
        </v-stepper-content>
      </v-stepper>
    </v-flex>
  </v-layout>
</template>
  
<script>
import FormInscription from "../components/Form/FormInscription";
import FormFiles from "../components/Form/FormFiles";
import axios from "axios";

export default {
  components: { FormInscription, FormFiles },
  data() {
    return {
      stepForm: 1,
      form_data: new FormData(),
    };
  },
  methods: {
    continu({ form, stepForm }) {
      this.stepForm = stepForm;
      this.form_data.append('user', `${form.name} ${form.surname}`)
      
      this.form_data.append('email', form.email)
      
      this.form_data.append('numero',form.adresse.numero);
      this.form_data.append('voie',form.adresse.voie);
      this.form_data.append('code_postal',form.adresse.cp);
      this.form_data.append('ville',form.adresse.ville);
      this.form_data.append('fixe', form.phone.fixe);
      this.form_data.append('mobile', form.phone.mobile);
      this.form_data.append('birthday', `${String(form.birthday.day).padStart(2, '0')} / ${String(form.birthday.month).padStart(2, '0')} / ${form.birthday.year}`);
      this.form_data.append('carteVital',form.carteVital);
      this.form_data.append('dose',form.dose);
    },
    submit(form) {
      this.form_data.append("identity", form.identity);
      this.form_data.append("vital", form.vital);

      axios
        .post("api/mail.php", this.form_data)
        .then((res) => res.data.status ? this.$emit('changeKey', 4): this.$emit('changeKey', 5))
        .catch((error) => {console.error(error) ; this.$emit('changeKey', 5)});
    },
    retour(args){
      this.$emit('changeKey',args)
    }
  },
};
</script>