<template>
  <v-form
    enctype="multipart/form-data"
    ref="formFile"
    v-model="valid"
    lazy-validation
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <p>
            Les fichiers transmis nous servent à vérifier l'authenticité de
            votre inscription. Les données que vous nous transmettez sont
            utilisées dans le but de vérifier votre demande faite et de vous
            re-contacter. Ces données sont utilisées par UML74.fr, responsable
            du traitement des données, et ne seront jamais cédées à un tiers ni
            utilisées à d’autres fins que celles détaillées précédemment.
          </p>
        </v-col>

        <v-col cols="12" v-if="ActivateCVital">
          <v-file-input
            accept="image/png, image/jpeg"
            label="Carte vitale"
            v-model="carte.vital"
            :rules="[rules.empty,rules.sizeFile, () => !carte.vital || carte.identity.name != carte.vital.name || 'le fichier doit être différent']"
            show-size
          ></v-file-input>
        </v-col>
        <v-col cols="12" v-if="ActivateCIdentity">
          <v-file-input
            accept="image/png, image/jpeg"
            label="Carte d'identité"
            v-model="carte.identity"
            :rules="[rules.empty,rules.sizeFile,() => !carte.identity.name || carte.identity.name != carte.vital.name || 'le fichier doit être différent']"
            show-size
          ></v-file-input>
        </v-col>
        <v-col>
          <vue-recaptcha sitekey="6LfBPIcaAAAAAMPxOMfVx7k7bUnJJaWEh55IFJ9u" @verify="recapt"></vue-recaptcha>
        </v-col>
        <v-col></v-col>
        <v-col class="text-right">
          <v-btn color="accent" @click.prevent="preview" :disabled="!valid" v-if="recaptcha"
            >envoyer</v-btn
          ></v-col
        >
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
export default {
  components: {VueRecaptcha},
  props: ['ActivateCIdentity', 'ActivateCVital'],
  data() {
    return {
      valid: true,
      carte: {
        identity: {name:''},
        vital: {name:''},
      },
      rules: {
        empty: (value) => !!value || "fichier image requis",
        sizeFile: (value) => !value || value.size <= 1000000 || "file size should be less than 1 MB!",
      },
      recaptcha: true,
      loading: ''
    };
  },
  methods: {
    preview() {
      if (this.$refs.formFile.validate()) {
        let content = {};
        if(this.ActivateCIdentity){
          content.identity = this.carte.identity
        }
        if(this.ActivateCVital){
          content.vital = this.carte.vital
        }
        this.$emit("submit", content);
      }
    },
    recapt(response) {
      if(response){
        this.recaptcha = true
      }
    },
  },
};
</script>

<style>
</style>